<template>
  <div>
    <b-modal
      id="modal-1"
      ref="consolidateMessage"
      title="Important"
      ok-title="Continue"
      @ok="selectAddress"
      @cancel="closeModal"
      centered
    >
    <b-card-text class="text">Once the package is consolidated you cannot remove items from it.</b-card-text>

    </b-modal>

    <SelectAddress ref="addressSelection" flag="false"></SelectAddress>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import SelectAddress from '../SelectAddress.vue';

export default {
  name: 'ConsolidateDialog',
  components: {
    SelectAddress,
  },
  computed: mapState({
    allConsolidatedItems: state => {
      return state.userItems.consolidate
    },
  }),
  methods: {
    ...mapMutations({
      refreshConsolidate: 'userItems/REFRESH_CONSOLIDATE',
    }),
    showModal() {
      this.$refs.consolidateMessage.show()
    },
    closeModal() {
      this.$refs.consolidateMessage.hide()
    },
    selectAddress() {
      let element = this.$refs.addressSelection
      element.showModal()
    },
  },
}
</script>

<style scoped>

#modal-1{
    display: flex;
    justify-content: center;
    align-items: center;
}

.text{
    color: #414141;
    font-weight: 500;
}
</style>