<template>
  <div>
    <b-modal
      id="modal-center"
      centered
      v-model="modalShow"
      ref="selectOrEditAddress"
      hide-footer
      hide-header
      size="lg"
    >
      <div class="root">
        <div class="title">
          <div class="heading"> Select an existing delivery address or add a new one</div>
          <div class="cross" @click="close">X</div>
        </div>
        <div class="conten">
          <div class="boxes">
            <div v-for="(address,key) in previousAddresses" :key="key" class="box" >
              <div class="card-top">
                <div class="deleteIcon"><img :src="deleteIcon" @click="deleteAddress(address.id)"/></div>
                <div class="h1">{{address.fullName}}</div>
              </div>
              <div class="p">
                <div>{{address.addressLine1}}, {{address.addressLine2}}</div>
                <div>{{address.city}}, {{address.state}}, {{address.country}}</div>
                <div>{{address.zipCode}}</div>
                <div>{{address.phone}}</div>
              </div>
              <div class="buttons">
                <b-button
                  class="bttn1"
                  variant="no-color"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  @click="editAddress(address.id)"
                >
                  Edit
                </b-button>
                <div class="default-text" @click="setDefaultAddress(address.id)" v-if="!address.isDefault && f">Set as default</div>
                <div class="default-text2" v-if="address.isDefault && f">Default address</div>
                <b-button
                  :disabled="isButtonDisabled"
                  v-if="!f && !isCalledFromPickup"
                  class="bttn2"
                  variant="no-color"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  @click="consolidateStatus(address.id)"
                >
                  Select
                </b-button>
                <b-button
                  :disabled="isButtonDisabled"
                  v-if="!f && isCalledFromPickup"
                  class="bttn2"
                  variant="no-color"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  @click="setShippingAddress(address.id)"
                >
                  Select
                </b-button>
              </div>
            </div>
            <div class="newbox" @click="newAddress">
              <div class="plus"><span class="plus_">+</span></div>
              <b-button class="newAdd" 
                        variant="no-color"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        >
                Add New Address
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <NewAddress ref="newAddress"></NewAddress>
    <div>
    <!-- Pass userId as a prop to the child component -->
    <ChildComponent :userId="userId" />
  </div>
  <div v-if="user && user.userId">{{ user.userId }}</div>
  </div>
</template>
<script>
import { BModal, VBModal, BAlert, BCardText } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import Ripple from "vue-ripple-directive";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";
import NewAddress from '../dashboard/MyOrders/AddAddress.vue'

import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
} from "bootstrap-vue";
import DeleteIcon from "../dashboard/Icons-images/deleteIcon.svg";
import { tryOnUnmounted } from '@vueuse/core';

export default {
  props: ['flag', 'pickupCall'],
  components: {
    BCardCode,
    BButton,
    BModal,
    BAlert,
    BRow,
    BImg,
    BCol,
    BLink,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    NewAddress,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  data() {
    return {
      modalShow: false,
      isNewAddressComp: false,
      perPage: 25,
      currentPage: 1,
      deleteIcon: DeleteIcon,
      f: false,
      isButtonDisabled: false,
      userId: null,
      isCalledFromPickup: ''
    }
  },
  computed: mapState({
    previousAddresses: state => {
      return state.address.addresses
    },
  }),
  mounted() {
    this.f = (this.flag == "true")? true : false;
    this.isCalledFromPickup = this.pickupCall ? true : false
    this.getAddedAddresses()
  },
  methods: {
    close() {
      this.modalShow = false
      this.$root.$emit('closeModal')
    },
    showModal() {
      this.$refs.selectOrEditAddress.show()
    },
    ...mapMutations({
      getSelectedId: 'address/GET_SELECTED_ID',
      updateAddress: 'address/UPDATE_ADDRESS_DETAILS',
      newAddressComp: 'address/SET_NEW_ADDRESS_VALUE',
      refreshItemsList: 'userItems/REFRESH_CONSOLIDATE',
      refreshSelectedAddress: 'address/UPDATE_SELECTED_ADDRESS',
      setSelectVal: 'address/SET_SELECT_VAL',
      setSelectedAddressId: 'userItems/SET_SELECTED_ADDRESS_ID',
    
      
    }),
    ...mapActions({
      getAddedAddresses: 'address/getAddedAddresses',
      addConsolidatedItemsToShippingPackage: 'userItems/addConsolidatedItems',
      getShippedItems: 'shippingPackages/getShippedItems',
      refreshNonSelectedItemsList: 'userItems/showUserItems',
      deleteSelectedAddress: 'address/deleteAddress',
      patchAddressToDefault: 'address/patchAsDefaultAddress',
      patchAddressOnSelect : 'address/patchAddressOnSelect'
    }),
    async consolidateStatus(id) {
      this.isButtonDisabled = true
      this.setSelectedAddressId(id)
      await this.addConsolidatedItemsToShippingPackage()
      this.refreshItemsList()
      this.getShippedItems()
      this.refreshNonSelectedItemsList({ pageSize: this.perPage, currentPage: this.currentPage })
      this.setSelectVal(1)
      this.$router.push({name : 'consolidationStatus'})
    },
    editAddress(id) {
      let element = this.$refs.newAddress
      element.showModal()
      this.getSelectedId(id)
      this.updateAddress()
    },
    newAddress() {
      this.isNewAddressComp = true
      this.refreshSelectedAddress()
      this.newAddressComp(this.isNewAddressComp)
      let element = this.$refs.newAddress
      element.showModal()
    },
    deleteAddress(id) {
      this.deleteSelectedAddress(id)
    },
    async setDefaultAddress(id) {
      await this.patchAddressToDefault(id);
      await this.$refs.selectOrEditAddress.hide();
    },
    async setShippingAddress(id){
      await this.patchAddressOnSelect({id: id});
      await this.$refs.selectOrEditAddress.hide();
    }
  },
}
</script>
<style scoped>
.root {
  min-height: 570px;
  padding: 2rem;
}
.title {
  margin-bottom: 2.5rem;
  display: flex;
}
.heading {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #414141;
}
.cross {
  margin-left: auto;
  width: 30px;
  height: 30px;
  border: 2px solid #4385f5;
  color: #4385f5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  cursor: pointer;
}
.boxes {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.box {
  min-width: 203px;
  min-height: 164px;
  background: #f8f8f8;
  padding: 1rem;
  margin-bottom:0.6rem;
}
.h1 {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}
.p {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #555555;
}
.buttons {
  display: flex;
  gap: 1.5rem;
  margin-top: 1rem;
}
.bttn1 {
  background: #d9e7fd;
  color: #4385f5;
  width: 79px;
  height: 27px;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  padding: 0;
}
.bttn2 {
  padding: 0;
  background: #4385f5;
  color: #ffffff;
  width: 79px;
  height: 27px;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
}
.newbox {
  border: 1px dashed #4385f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap:0.6rem;
  cursor: pointer;
  width: 203px;
  height: 180px;
}
.newAdd {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #4385f5;
}
.plus {
  width: 25px;
  height: 25px;
  background: #4385f5;
  border-radius: 4rem;
}
.plus_ {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.35rem;
  color: #ffffff;
}
.card-top {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.default-text {
  color:#414141;
  font-size: 0.9rem;
  cursor: pointer;
}
.default-text2 {
  color:#414141;
  font-size: 0.9rem;
  cursor: pointer;
}
.default-text:hover {
  color:#4385f5;
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (max-width: 1200px){
  .root {
   min-height: 570px;
   padding: 0rem;
  }
  .boxes {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  margin: 0.5rem;
 }
 .box {
  min-width: 203px;
  min-height: 164px;
  background: #f8f8f8;
  padding: 1rem;
 }
 .heading {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 30px;
  color: #414141;
 }
}
</style>