<template>
  <div class="root__">
    <div v-if="packagesReadyToBeShipped() == 0">
      <EmptyState />
    </div>
    <div
      v-else 
      v-for="(item, indx) in getAllPackageDetails"
      :key="indx"
      class="root_box"
    >
      <ReadyForShippingComp v-if="item.paymentStatus === 'Done' && item.shippingStatus!='Completed' && item.shippingStatus!='Delivered'"  :value="item" />
    </div>
  </div>
</template>
<script>

import { mapMutations, mapState, mapActions } from "vuex";
import { BRow, BDropdown, BButton } from "bootstrap-vue";
import ReadyForShippingComp from "./ReadyForShippingComp.vue";
import EmptyState from './EmptyStateReadyForShipping.vue'

export default {
  components: {
    ReadyForShippingComp,
    BRow,
    BDropdown,
    BButton,
    EmptyState
  },
  data() {
    return {
    };
  },
  computed: mapState({
    getAllPackageDetails: (state) => {
      return state.shippingPackages.itemsToBeShipped;
    },
  }),
  mounted() {
    this.getShippedItems()
    this.setMyOrdersNavbar('readyForShipping')
    this.setSidebarRoute('productsInWarehouse')
    this.setNavbarRoute('dashboardLanding')
    this.packagesReadyToBeShipped()
  },
  methods: {
    ...mapActions({
      getShippedItems: 'shippingPackages/getShippedItems',
    }),
    ...mapMutations({
      setMyOrdersNavbar: 'routesStore/SET_MY_ORDERS_NAVBAR',
      setSidebarRoute: 'routesStore/SET_SIDEBAR',
      setNavbarRoute: 'routesStore/SET_NAVBAR'
    }),
    packagesReadyToBeShipped() {
      const arr = this.getAllPackageDetails.filter(item => item.paymentStatus === 'Done' && item.shippingStatus!='Completed')
      return arr.length
    }
  },
};
</script>

<style scoped>
.row {
  display: flex;
  justify-content: space-around;
}
.customDropdown {
  color: #4385f5;
}
.newbox {
  border: 1px dashed #4385f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  cursor: pointer;
}
.root_box {
  color: #F8F8F8;
}
@media screen and (min-width: 1250px) and (max-width: 1400px) {
  .root__ {
    width: 65em;
  }
}
</style>